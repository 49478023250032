<template>
  <ClientOnly>
    <GoogleAutoComplete
      v-bind="$attrs"
      class="placeholder-black"
      :placeholder="placeholder"
      country="us"
      @placechanged="googleAutocompleteChanged"
    />
  </ClientOnly>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Google from './GetAddressData'

  export default {
    name: 'GoogleAddressLookup',
    inheritAttrs: false,
    props: {
      /** The placeholder string that should appear in the text box */
      placeholder: {
        type: String,
        default: 'Enter Your Address'
      },

      /** If false we do not run the "create address" function and just emit the address to parent */
      create: {
        type: Boolean,
        default: true
      }
    },

    emits: ['error', 'created'],

    data() {
      return {
        address: null
      }
    },

    computed: {
      ...mapGetters('regions', ['xFindRegionFromLocationAndZip', 'xFindLocationFromZip']),
      ...mapGetters('authuser', ['xIsAuthenticated']),

      /**
       * The function we use to save the address. If auth'd we send to server if unauth'd we save in authuser module state
       */
      createFunction() {
        if (this.xIsAuthenticated) {
          return this.xCreateNewAddress
        }

        return this.xMakeTempAddress
      }
    },

    watch: {
      /**
       * Called when data.address changes. Sends new address to server when pulled from google maps
       */
      async address() {
        /**
         * Call the create function passed in via prop. As of 1.0 this function can be one of two things
         * 1. xCreateAddress found in the Authuser vuex module. This sends the address to the server to be saved to the
         * currently authenticated user.
         *
         * 2. xMakeTemporaryAddress found in authuser vuex module. This creates a temporary address that can be used
         * until the user creates an account
         */
        // console.log("calling create fn");
        if (this.create) {
          await this.createFunction(this.address)
        }
        this.$emit('created', this.address)
      }
    },

    methods: {
      ...mapActions('authuser', ['xCreateNewAddress', 'xMakeTempAddress']),

      /**
       * Called when google maps returns an autocompleted address. Formats the data and saves it in this component
       * @param addressData from google maps
       * @param placeResultData from google maps
       */
      googleAutocompleteChanged(addressData, placeResultData) {
        // console.log("cats like food");
        this.address = Google.getWPAddressData(
          addressData,
          placeResultData,
          this.xFindLocationFromZip,
          this.xFindRegionFromLocationAndZip
        )
        if (!this.address) {
          this.$emit('error')
        }
      }
    }
  }
</script>
