import { Loader } from '@googlemaps/js-api-loader'

const loader = new Loader({
  apiKey: 'AIzaSyBv0YQxCTcpiaa-dOn3EpZB0uFythDhhFE',
  version: 'weekly',
  libraries: ['places']
})

export const googleMapsSDK = () => {
  return new Promise((resolve, reject) => {
    loader
      .load()
      .then((google) => {
        resolve(google)
      })
      .catch((e) => {
        if (process.client) {
          // eslint-disable-next-line no-console
          console.warn('Error loading Google SDK:', e.message)
          reject(e)
        }
      })
  })
}
